import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { useStyles } from './Gallery.styles'
import { ImageCard } from '../../components'
import itemData from './../../data/vehicleImages'

interface GalleryScreenProps {}

const GalleryScreen: React.FC<GalleryScreenProps> = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.pageHeading}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item md={12}>
              <Typography variant="h4">Our Work Collection</Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Grid container alignItems="center" className={classes.grid}>
        {itemData.map((data: any, index: number) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <ImageCard image={data.img} title={data.title} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default GalleryScreen
