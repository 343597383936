import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  list: {
    width: 250,
    textAlign: 'center',
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  fullList: {
    width: 'auto',
  },
  rootDrawer: {
    '& .MuiDrawer-paper': {
      color: 'white',
      background: '#e9565d6b !important',
      borderBottom: '3px solid red',
    },
  },
})
