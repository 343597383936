import { Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { Footer, Tabs } from './components'
import { Home, About, Gallery } from './Screens'
import { theme } from './theme/theme'
import './App.css'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Tabs />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/gallery" component={Gallery} />
        </Switch>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default App
