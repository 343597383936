import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: 345,
    boxShadow: '0px 7px 12px -1px rgba(255, 0, 0,0.25)',
    margin: '20px',
  },
})
interface ImageCardProps {
  image: string
  title: string
}
const ImageCard: FC<ImageCardProps> = ({ image, title }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="240"
          width="400"
          image={image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
export default ImageCard
