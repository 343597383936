import { BannerSection, AboutSection } from './Sections'

const HomeScreen = () => {
  return (
    <>
      <BannerSection />
      {/* <GallerySection /> */}
      <AboutSection />
    </>
  )
}

export default HomeScreen
