const itemData = [
  {
    img: '/static/images/custom1.jpg',
    title: 'Jeep',
    author: 'brett-sayles',
    cols: 2,
  },
  {
    img: '/static/images/custom2.jpg',
    title: 'BMW',
    author: 'amorales',
  },
  {
    img: '/static/images/custom1.jpg',
    title: 'Toyota',
    author: 'dmitriy-ganin',
  },
  {
    img: '/static/images/custom2.jpg',
    title: 'Range Rover',
    author: 'Mike',
  },
  {
    img: '/static/images/custom1.jpg',
    title: 'Mercedes',
    author: 'pixabay',
  },
  {
    img: '/static/images/custom2.jpg',
    title: 'Range Rover',
    author: 'jill111',
    cols: 2,
  },
  {
    img: '/static/images/custom1.jpg',
    title: 'Ferrari',
  },

  {
    img: '/static/images/custom2.jpg',
    title: 'BMW',
    author: 'director90',
  },
]

export default itemData
