import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      padding: theme.spacing(2),
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },

    imageList: {
      width: '100%',
      height: '100%',
      transform: 'translateZ(0)',
    },
    titleBar: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'white',
    },
    margin: {
      margin: theme.spacing(1),
    },
    typography: { padding: theme.spacing(5) },
  }),
)
