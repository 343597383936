import { createTheme } from '@material-ui/core/styles'
export const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0000',
    },
    secondary: {
      main: '#A4C639',
    },
  },
})
