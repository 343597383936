import { Fab, Typography, Slide } from '@material-ui/core'
import { REGISTER_LINK } from '../../../../constants'
import PANALAX_LOGO from '../../../../assets/panalax-logo.png'
import { useStyles } from './Banner.styles'

const BannerSection = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.backgroundImage}>
        <div className={classes.textContent}>
          <Slide
            direction="right"
            timeout={2000}
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Typography variant="h6" color="primary">
                Welcome to{' '}
                <img src={PANALAX_LOGO} alt={PANALAX_LOGO} width={200} />
              </Typography>
              <Typography variant="h6" color="primary"></Typography>
              <Typography variant="h4">
                THE SECOND BEST WAY <br /> TO KEEP YOUR CUSTOMERS HAPPY
              </Typography>
              <Typography variant="subtitle2">
                (even we could not think of a better way than the work you
                already do)
              </Typography>
              <Typography variant="body1">
                We built this custom “shop chat”, specifically for the custom
                shop community
              </Typography>
            </div>
          </Slide>
          <Slide
            direction="left"
            timeout={2000}
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <Fab
              variant="extended"
              href={REGISTER_LINK}
              size="medium"
              color="primary"
              aria-label="add"
              className={classes.margin}
            >
              Register
            </Fab>
          </Slide>
        </div>
      </div>
      {/* Contact Form */}
      {/* <div className={classes.requestForm}>
        <div className={classes.container}> */}
      {/* @ts-ignore */}
      {/* <Grid container spacing={3} align="left">
            <Grid item md={8} sm={12}>
              <Typography variant="h4">
                Request a call back right now ?
              </Typography>
              <Typography variant="caption">
                Support team available 24/7.
              </Typography>
            </Grid>
            <Grid item md={4} sm={12}>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                Contact Us
              </Fab>
            </Grid>
          </Grid>
        </div>
      </div> */}
    </>
  )
}

export default BannerSection
