import React from 'react'
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useStyles } from './About.styles'

interface AboutScreenProps {}

const AboutScreen: React.FC<AboutScreenProps> = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <>
      <div className={classes.pageHeading}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item md={12}>
              <Typography variant="h4">About Us</Typography>
              <Typography variant="caption">
                We have over 20 years of experience
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.aboutUs}>
        <Grid container alignItems="center">
          {/* @ts-ignore */}
          <Grid item lg={8} md={12} sm={12} align="left">
            <div className={classes.leftText}>
              <Typography variant="caption">Who we are</Typography>
              <Typography variant="h2">
                Get to know about <br />
                <em>our company</em>
              </Typography>
              <Typography variant="body1">
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`Welcome to Panalax. We built this custom “shop chat”, specifically for the custom shop community. Panalax
                makes it easy and inexpensive for you to stay in constant touch with your clients, to share pictures of the builds
                in progress, discuss options, trims, parts and costs, ask and answer questions and record agreements on changes.

                Panalax stores every message and picture you exchange with a client in one place and also lets you share build
                sheets if you choose.

                We like to keep things simple. There are no tiers, nickel & dime options or gotchas. Unlimited usage and users
                costs $50 per month includes 1,000 text messages. Additional text messages are $0.01 each and can be disabled
                if you choose. Cancel any time after the first 3 months.

                Whether you build cars, motorcycles, boats or barns, Panalax will drastically change the ease with which you talk
                to your customers and will make your clients feel like you are constantly in touch with them.

                Thank you for your business! Please let us know how we are doing.`}
                </span>
              </Typography>
            </div>
          </Grid>
          {isDesktop && (
            <Grid item lg={4} md={12} sm={12}>
              <img src="/static/images/custom-red-car.jpg" alt="" />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

export default AboutScreen
