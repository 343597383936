import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  pageHeading: {
    textAlign: 'center',
    backgroundImage: `url("static/images/panalax-background.jpg")`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '230px 0px 150px 0px',
    color: '#fff',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.2)',
      zIndex: 1,
    },
    '& .container': {
      position: 'relative',
      zIndex: 2,
    },
    '& h1': {
      textTransform: 'capitalize',
      fontSize: '36px',
      fontWeight: 700,
      letterSpacing: '2px',
      marginBottom: '18px',
    },
    '& span': {
      fontSize: '15px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: '#fff',
      display: 'block',
    },
  },
  aboutUs: {
    padding: '80px 0px',
    color: 'black',
    position: 'relative',

    '& .container': {
      position: 'relative',
      zIndex: 2,
    },
    '& .MuiGrid-container': {
      display: 'flex',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.1)',
      zIndex: 1,
    },

    '& span': {
      fontSize: '15px',
      color: 'black',
      letterSpacing: '1px',
      display: 'block',
    },
    '& h2': {
      fontSize: '36px',
      fontWeight: '600',
      marginBottom: '35px',
    },

    '& em': {
      fontStyle: 'normal',
      color: theme.palette.primary.main,
    },

    '& p': {
      color: '#fff',
      marginBottom: '40px',
    },
    '& img': {
      padding: theme.spacing(3),
      maxWidth: '450px',
      marginRight: '5px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '90%',
        padding: theme.spacing(0),
      },
    },
  },
  leftText: {
    maxWidth: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      maxWidth: '90%',
      padding: theme.spacing(1),
    },
  },
}))
