import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    border: '1px solid',
  },
  padding: {
    // padding: theme.spacing(2),
  },
  demo2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    boxShadow: '0px 7px 12px -1px rgba(255, 0, 0,0.75)',
    backgroundColor: '#1C1C1C',
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
    '& MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiFab-root ': {
      marginRight: '20px !important',
    },
  },
}))
