import clsx from 'clsx'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core'
import { useStyles } from './Drawer.styles'
import { Link } from 'react-router-dom'

type Anchor = 'top' | 'left' | 'bottom' | 'right'
interface DrawerProps {
  open: boolean
  toggleDrawer: any
}
const NavDrawer: React.FunctionComponent<DrawerProps> = ({
  open,
  toggleDrawer,
}) => {
  const classes = useStyles()

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button>
          <ListItemText>
            <Link to="/">Home</Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <Link to="/about">About Us</Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <Button
            href="/guides/shop"
            fullWidth
            variant="contained"
            color="primary"
            target="_blank"
          >
            Site Guide
          </Button>{' '}
        </ListItem>
        <ListItem button>
          <Button
            fullWidth
            href="/guides/client"
            variant="contained"
            color="primary"
            target="_blank"
          >
            Client Guide
          </Button>
        </ListItem>
      </List>
    </div>
  )

  return (
    <div>
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className={classes.rootDrawer}
      >
        {list('top')}
      </SwipeableDrawer>
    </div>
  )
}

export default NavDrawer
