import { NavLink } from 'react-router-dom'
import { Container, Grid, Link, Typography } from '@material-ui/core'
import PANALAX_LOGO from '../../assets/panalax-logo.png'
import { useStyles } from './Footer.styles'

const Footer = () => {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item md={4} sm={6} xs={12} className={classes.align}>
            <Typography variant="h4" color="primary">
              <img src={PANALAX_LOGO} alt={PANALAX_LOGO} width={200} />
            </Typography>
            <Typography variant="subtitle1">
              Welcome to Panalax. We built this custom “shop chat”, specifically
              for the custom shop community
            </Typography>
            <br />
          </Grid>
          <Grid item md={2} sm={3} xs={12} className={classes.align}>
            <Typography variant="h4" color="primary">
              Pages
            </Typography>
            <Typography variant="body1">
              <NavLink to="/">Panalax Home</NavLink>
              <br />
              <NavLink to="about">About Us</NavLink>
              <br />
            </Typography>
          </Grid>
          <br />
          <Grid item md={3} sm={3} xs={12} className={classes.align}>
            <Typography variant="h4" color="primary">
              Contact Us
            </Typography>
            <Typography variant="body1">
              <Link href="tel:+1-800-701-3710">+1-800-701-3710</Link>
              <br />
              <Link href="mailto:sales@panaton.com">sales@panaton.com</Link>
            </Typography>
            {/* <div className="contact-form">
              <form id="contact footer-contact" action="" method="post">
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name="name"
                      type="text"
                      id="name"
                      placeholder="Full Name"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name="email"
                      type="text"
                      id="email"
                      placeholder="E-Mail Address"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextareaAutosize
                      name="message"
                      rows={6}
                      id="message"
                      placeholder="Your Message"
                      required
                    ></TextareaAutosize>
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      color="secondary"
                      aria-label="add"
                      className={classes.margin}
                    >
                      Send Message
                    </Fab>
                  </Grid>
                </Grid>
              </form>
            </div> */}
          </Grid>
          <Grid item md={3} sm={12} xs={12} className={classes.align}>
            <Typography variant="h4" color="primary">
              Address
            </Typography>
            <Typography variant="body1">
              202 N 9th St, Suite
              <br />
              201, Boise, ID 83702
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
