import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  withStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles'
import { Fab, Tabs, Tab, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import PANALAX_LOGO from '../../assets/panalax-logo.png'
import { Drawer } from '..'
import { useStyles } from './Tabs.styles'

interface StyledTabsProps {
  value: string
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

const StyledTabs = withStyles((theme: Theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 70,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
))

interface StyledTabProps {
  label: string
  value: string
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

export default function CustomizedTabs(props: any) {
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [value, setValue] = React.useState('/')
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpenDrawer(!openDrawer)
    }

  useEffect(() => {
    setValue(location.pathname)
  }, [location])
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue)
    history.push(newValue)
  }
  return (
    <>
      <Drawer open={openDrawer} toggleDrawer={toggleDrawer} />
      <div className={classes.root}>
        <div className={classes.demo2}>
          <img src={PANALAX_LOGO} width={300} alt={PANALAX_LOGO} />
          {!isMobile ? (
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              <StyledTab label="Home" value="/" />
              <StyledTab label="About Us" value="/about" />
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                href="/guides/client"
                target="_blank"
              >
                Client Guide
              </Fab>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                href="/guides/shop"
                target="_blank"
              >
                Site Guide
              </Fab>
            </StyledTabs>
          ) : (
            <MenuIcon style={{ color: 'white' }} onClick={toggleDrawer(true)} />
          )}
        </div>
      </div>
    </>
  )
}
