import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  pageHeading: {
    textAlign: 'center',
    backgroundImage: `url("static/images/panalax-background.jpg")`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '230px 0px 150px 0px',
    color: '#fff',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.2)',
      zIndex: 1,
    },
    '& .container': {
      position: 'relative',
      zIndex: 2,
      textAlign: 'center',
    },
    '& h1': {
      textTransform: 'capitalize',
      fontSize: '36px',
      fontWeight: 700,
      letterSpacing: '2px',
      marginBottom: '18px',
      textAlign: 'center',
    },
    '& span': {
      fontSize: '15px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: '#fff',
      display: 'block',
    },
  },
  grid: {
    padding: theme.spacing(2),
    '& .MuiGrid-item ': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))
