import { makeStyles } from '@material-ui/core/styles'
import BgLogo from '../../../../assets/panalax-background.jpg'

export const useStyles = makeStyles((theme) => ({
  textContent: {
    textAlign: 'left',
    width: '75%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',
      fontSize: '22px',
      textTransform: ' uppercase',
      fontWeight: '700',
      letterSpacing: '1px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      '& img': {
        marginLeft: theme.spacing(1),
      },
    },
    '& h4': {
      marginBottom: '15px',
      textTransform: 'uppercase',
      fontSize: '44px',
      fontWeight: '700',
      letterSpacing: '2.5px',
      color: '#FFF',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '25px',
        fontSize: '28px',
        lineHeight: '36px',
        letterSpacing: '1px',
      },
    },
    '& .MuiTypography-subtitle2': {
      color: 'white',
    },
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.primary.main,
    },
    '& p': {
      maxWidth: '570px',
      color: '#fff',
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '30px',
      marginBottom: '40px',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '25px',
        marginBottom: '30px',
      },
    },
  },
  backgroundImage: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    height: '95vh',
    backgroundImage: `url(${BgLogo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },

  requestForm: {
    backgroundColor: theme.palette.secondary.main,
    padding: '40px 0px',
    color: '#fff',

    '& h4': {
      fontSize: '22px',
      fontWeight: 600,
    },
    '& span': {
      fontSize: '15px',
      fontWeight: 400,
      display: 'inline-block',
    },
  },
  container: {
    width: '80%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  margin: {
    margin: theme.spacing(1),
  },
}))
