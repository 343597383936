import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    width: '100%',
    bottom: 0,
    backgroundColor: '#232323',
    padding: '20px 0px',
    color: '#fff',
    '& h4': {
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      marginBottom: '35px',
    },
    '& p': {
      color: '#fff',
    },
    //form styling commented out for later use
    // '& .contact-form input': {
    //   borderRadius: '20px',
    //   height: '40px',
    //   lineHeight: '40px',
    //   display: 'inline-block',
    //   padding: '0px 15px',
    //   color: '#aaa !important',
    //   backgroundColor: '#343434',
    //   fontSize: '13px',
    //   textTransform: 'none',
    //   boxShadow: 'none',
    //   border: 'none',
    //   marginBottom: '15px',
    // },
    // '& .contact-form input:focus': {
    //   outline: 'none',
    //   boxShadow: 'none',
    //   border: 'none',
    //   backgroundColor: '#343434',
    // },
    // '& .contact-form textarea': {
    //   borderRadius: '20px',
    //   width: '100%',
    //   height: '120px',
    //   maxHeight: '200px',
    //   minHeight: '120px',
    //   display: 'inline-block',
    //   padding: '5px',
    //   color: '#aaa !important',
    //   backgroundColor: '#343434',
    //   fontSize: '13px',
    //   textTransform: 'none',
    //   boxShadow: 'none',
    //   border: 'none',
    //   marginBottom: '15px',
    // },
    // '& .contact-form textarea:focus': {
    //   outline: 'none',
    //   boxShadow: 'none',
    //   border: 'none',
    //   backgroundColor: '#343434',
    // },

    // '& .contact-form ::-webkit-input-placeholder': {
    //   /* Edge */ color: '#aaa',
    // },
    // '& .contact-form :-ms-input-placeholder': {
    //   /* Internet Explorer 10-11 */ color: '#aaa',
    // },
    // '& .contact-form ::placeholder': {
    //   color: '#aaa',
    // },
    '& a': {
      textDecoration: 'none',
      color: '#fff',
    },
    '& a:hover': {
      color: theme.palette.primary.main,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  align: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}))
