import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    zIndex: 1,
    width: '100%',
    boxShadow: '0px 7px 12px -1px rgba(255, 0, 0,0.75)',
    '& .MuiAppBar-positionStatic': {
      background: 'transparent',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 100,
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  registerButton: {
    marginLeft: 'auto !important',
    background: '#FF0000',
    color: 'white',
  },
  menuIcon: {
    marginLeft: 'auto !important',
  },
}))
