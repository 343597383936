import { makeStyles } from '@material-ui/core/styles'
import BgLogo from '../../../../assets/panalax-background.jpg'

export const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: theme.spacing(5),
  },
  aboutUs: {
    marginTop: theme.spacing(5),
    backgroundImage: `url(${BgLogo})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    padding: '100px 0px',
    color: '#fff',
    position: 'relative',

    '& .container': {
      position: 'relative',
      zIndex: 2,
    },

    '& span': {
      fontSize: '15px',
      color: '#fff',
      letterSpacing: '1px',
      display: 'block',
    },
    '& h2': {
      fontSize: '36px',
      fontWeight: '600',
      marginBottom: '35px',
    },

    '& em': {
      fontStyle: 'normal',
      color: theme.palette.primary.main,
    },

    '& p': {
      color: '#fff',
      marginBottom: '40px',
    },
    '& img': {
      padding: theme.spacing(3),
      maxWidth: '500px',
      marginRight: '5px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '90%',
        padding: theme.spacing(0),
      },
    },
  },
  leftText: {
    maxWidth: '500px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      maxWidth: '90%',
      padding: theme.spacing(1),
    },
  },
  fab: {
    '& a': {
      textDecoration: 'none',
      color: 'white',
    },
  },
}))
